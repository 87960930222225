// customizations for default uppy components styles
@import "@uppy/core/dist/style.css";
@import "@uppy/file-input/dist/style.css";
@import "@uppy/status-bar/dist/style.css";

@import '../styles/colors';

.uppy-FileInput-btn {
  color: #4a5568;
  border-color: $border-grey;
  &:hover {
    background-color: $white;
    color: $black;
    border-color: $black;
  }
}
