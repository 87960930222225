@import '/src/ui-kit/styles/colors';

.wrapper {
  @apply relative;
}
:global(#filter-datepicker-portal) {
  @apply absolute top-0;
  z-index: 9999;
}
:global(#filter-datepicker-portal .react-datepicker) {
  @apply flex border-0;
  box-shadow: 0px -1px 16px rgba(0, 0, 0, 0.1);
  :global(.react-datepicker__header) {
    @apply bg-transparent border-0 p-0 pt-4;
  }
  :global(.react-datepicker__current-month) {
    @apply font-normal  text-xl mb-4;
    line-height: 22px;
    color: #364250;
  }
  :global(.react-datepicker__navigation) {
    @apply top-4 outline-none;
  }
  :global(.react-datepicker__navigation--next) {
    @apply right-3.5;
  }
  :global(.react-datepicker__navigation--previous) {
    @apply left-3.5;
  }
  :global(.react-datepicker__triangle) {
    &:before,
    &:after {
      @apply hidden;
    }
  }
  :global(.react-datepicker__day-name) {
    @apply text-black-400 uppercase;
    font-size: 10px;
    line-height: 13px;
  }
  :global(.react-datepicker__day-names),
  :global(.react-datepicker__week) {
    @apply w-full flex flex-nowrap justify-between;
  }
  :global(.react-datepicker__day-names) {
    @apply py-0 px-4;
  }
  :global(.react-datepicker__week) {
    @apply text-base text-black-500;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  :global(.react-datepicker__day) {
    width: 36px;
    @apply text-black-500 font-normal flex justify-center items-center;
    box-sizing: border-box;
    line-height: 20px;
    height: 30px;
    &:not(:last-child) {
      margin-right: 9px;
    }
  }
  :global(.react-datepicker__day-name) {
    @apply flex justify-center items-center;
    width: 36px;
    box-sizing: border-box;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 9px;
    }
  }
  :global(.react-datepicker__month) {
    @apply m-0;
    padding: 1rem;
    padding-top: 10px;
  }
  :global(.react-datepicker__day) {
    @apply outline-none;
    border-radius: 50%;
    height: 30px;
    width: 36px;
  }
  :global(.react-datepicker__day.react-datepicker__day--selected),
  :global(.react-datepicker__day--in-range) {
    @apply text-white bg-primary-700 font-normal relative;
    border-radius: 50%;
    height: 30px;
    width: 36px;
    border: 2px solid #c4c4c4;
  }
  :global(.react-datepicker__day.react-datepicker__day--today),
  :global(.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range) {
    @apply bg-black-500 text-white;
    border: 2px solid #c4c4c4;
  }
  :global(.react-datepicker__day--keyboard-selected) {
    @apply bg-white;
  }
  :global(.react-datepicker__day--in-range) {
    @apply bg-gray-50 text-orange-700
  }
  :global(.react-datepicker__day--in-range.react-datepicker__day--range-start),
  :global(.react-datepicker__day--in-range.react-datepicker__day--range-end) {
    @apply bg-orange-700 text-white
  }
  :global(.react-datepicker__day--outside-month) {
    @apply pointer-events-none bg-white border-0
  }
  :global(.datepicker__navigation-icon::before),
  :global(.react-datepicker__navigation-icon::before) {
    border-width: 2px 2px 0 0;
    border-color: #8ea0b6;
  }
  :global(.react-datepicker__day--in-selecting-range.react-datepicker__day--in-selecting-range) {
    @apply bg-orange-700 text-white;
  }
  :global(.react-datepicker__day--in-selecting-range) {
    @apply bg-gray-200;
  }
}

:global(#filter-datepicker-portal .react-datepicker.react-datepicker--with-actions) {
  box-shadow: none;
  border-radius: revert;
}
