@import '../../styles/colors';

.pagination {
  @apply flex w-full;

  &AlignLeft {
    @apply justify-start;
  }
  &AlignCenter {
    @apply justify-center;
  }
  &AlignRight {
    @apply justify-end;
  }

  &WithResults {
    @apply justify-between;
    align-items: center;
  }

  .navContainer {
    @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px;
  }

  .arrowButton {
    @apply select-none cursor-pointer relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
    &Left {
      @apply rounded-l-md;
    }
    &Right {
      @apply rounded-r-md;
    }
  }

  .pageCell {
    @apply select-none cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
  }
  .currentPage {
    @apply z-10;
    color: $primary;
    border-color: $primary;
    background-color: $background-primary;
  }

  .disabled {
    pointer-events: none;
  }

  .resultInfo{
    width: 100%;
    display: flex;
    justify-items: left;
  }
}
