.listItem {
  :global(.hover-visible) {
    @apply w-0 m-0;
  }
  &:hover {
   :global(.hover-visible) {
     @apply ml-2;
     width: fit-content;
   }
  }
}