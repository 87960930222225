.error {
  @apply text-primary-700 text-sm;
}

.counter {
  margin-left: 0.6rem;
  z-index: 1;
  position: relative;
}

.menu {
  // IMPORTANT: This rule was, by some reason, important for the cases when
  //  there is a custom child. We need to investigate this.
  //overflow-y: unset !important;
  .menu-list {
    overflow-y: unset;
  }
}

.control {}
.hover {
  background-color: #f8f7fc;
  color: black;
}
