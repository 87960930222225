@import '../../styles/colors';
.MuiTextField-root {
  height: 2.125rem;
  &.table-input {
    height: 2.75rem;
    .MuiInputBase-input {
      height: 0.825rem;
    }
  }
  border-color: $border-grey;
  .MuiOutlinedInput-root fieldset,
  .MuiOutlinedInput-root:hover fieldset {
    border-color: $border-grey;
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: $primary;
    border-width: 1px;
  }
  .MuiInputBase-root {
    line-height: 1;
    .MuiOutlinedInput-input {
      @apply text-gray-500;
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
    }
    .MuiInputAdornment-root .MuiTypography-root {
      @apply text-gray-500;
      font-size: 0.875rem;
    }
  }
  .MuiFormHelperText-contained {
    margin-left: 0.25rem;
  }
  .Mui-disabled {
    opacity: 0.5;
  }
}
