@import '../../ui-kit/styles/colors';

.MuiDataGrid-root {
  @apply border-none sm:rounded-lg #{!important};

  // table container
  .MuiDataGrid-main {
    @apply min-w-full rounded-lg shadow overflow-hidden relative;
    .MuiDataGrid-overlay {
      min-height: 7rem;
    }
  }

  // column headers row
  .MuiDataGrid-columnHeaders {
    @apply bg-gray-50 border-b border-gray-200;
    &:hover .MuiDataGrid-columnSeparator {
      visibility: hidden !important;
    }

    // column header cell
    .MuiDataGrid-columnHeader {
      @apply px-3 py-2 text-left text-xs font-medium uppercase tracking-wider flex flex-row flex-1 text-gray-500 items-center select-none;
      &--sorted {
        color: $black;
      }
      .MuiDataGrid-columnHeaderTitleContainer {
        @apply p-0;
      }
      .MuiDataGrid-columnSeparator {
        visibility: hidden;
      }
      &:focus {
        outline: none !important;
      }
      &:focus-within {
        outline: none !important;
      }
    }

    // column header checkbox cell
    .MuiDataGrid-columnHeaderCheckbox {
      @apply p-0;
    }
  }

  // row
  .MuiDataGrid-row {
    &:nth-child(2n) {
      @apply bg-gray-50;
    }

    @apply bg-white;
    .vertical-dot-icon {
      visibility: hidden;
    }
    &:hover {
      .vertical-dot-icon {
        visibility: visible;
      }
    }
    &:hover,
    &.Mui-selected {
      background-color: $primary-light !important;
    }
    &.Mui-selected {
      box-shadow: inset 2px 0 0 $primary;
    }
    &.top-alignment {
      .MuiDataGrid-cell {
        align-items: start;
        &.input-with-helper-cell {
          padding-top: 0.7rem !important;
        }
      }
    }
    &.center-alignment {
      .MuiDataGrid-cell {
        align-items: baseline;
        padding-top: 0.7rem !important;
        &.input-with-helper-cell {
          padding-top: 0.5rem !important;
        }
      }
    }
  }

  // cell
  .MuiDataGrid-cell {
    @apply px-3 py-2 whitespace-nowrap text-sm text-gray-500 border-none #{!important};

    &:focus,
    &:focus-within {
      outline: none !important;
    }
    .MuiCheckbox-root:hover {
      background-color: transparent;
    }
  }

  //checkbox
  .MuiCheckbox-root {
    color: $border-grey;
    .MuiSvgIcon-root {
      font-size: 18px;
      font-weight: 100;
    }
    &.Mui-checked {
      color: $primary;
    }
  }
  .MuiDataGrid-selectedRowCount {
    display: none;
  }
  .MuiCircularProgress-root {
    color: $primary;
  }
}
