$white: #ffffff;
$primary: #f85737;
$black: #111827;
$purple: #818cf8;
$grey: #babfc7;
$light-grey: #f4f7fc;
$red: #eb5757;
$original-red: #FF0000;
$green: #219653;
$light-green: #059669;
$green500: #059669;
$border-grey: #d1d5db;
$background-grey: #e5e5e5;
$background-primary: #fff7ed;
$blue: #2972ff;
$primary-light: #fff9f8;
$background-light-grey: #F3F3F3;
