@import 'src/ui-kit/styles/colors';
.zip {
  position: relative;
  div {
    input {
      padding-top: 8px;
      padding-bottom: 8px;

      &:focus {
        border-color: $border-grey;
      }
    }
  }

  button {
    color: #7f8284;
  }
}
