@import './blueprintjs';
@import './uppy';
@import './colors';
@import './mixins';

@tailwind base;
@tailwind components;
@tailwind utilities;
.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid $primary;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 14px solid $green;
}
.white {
  color: $white;
}

.primary {
  color: $primary;
}

.black {
  color: $black;
}

.grey {
  color: $grey;
}
.grey-3 {
  color: #828282;
}

.lightgrey {
  color: $light-grey;
}

.purple {
  color: $purple;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}

.bg-primary {
  background-color: $background-primary;
}
.z-minus-1 {
  z-index: -1;
}
.outline-primary {
  border: 1px solid $primary;
}
.error {
  @include show-error-state();
}
#clearent-payment-errors.clearent-payment-errors {
  color: $red;
}
.text-ellipsis {
  text-overflow: ellipsis;
}

:root {
  --app-height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  color: $black;
  background-color: $background-grey;

  &.mobile {
    background-color: $background-light-grey;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
    max-height: var(--app-height);

    #root {
      @apply w-screen h-screen overflow-hidden;
      max-height: 100vh;
      max-height: var(--app-height);
    }
  }
}
