@import '../../styles/colors';
@import 'src/ui-kit/styles/mixins';
.inputWrapper {
  line-height: 1;
  div {
    line-height: 1;
  }
}
.input {
  @apply form-input shadow-sm block w-full rounded-md text-base;
  position: relative;
  border-color: $border-grey;
  line-height: 1.1876rem;
  font-size: 0.875rem;
  &.leftPadding {
    padding-left: 36px;
  }
  &.textarea::placeholder {
    @apply text-gray-500;
  }
  &:focus {
    @apply ring-0 border-primary-700;
  }
  &Error {
    @include show-error-state();
  }
  &[disabled] {
    opacity: 0.5;
  }
  &Table {
    padding: 0.25rem 0.5rem;
  }
}

.checkbox {
  @apply form-checkbox focus:outline-none focus:ring-transparent rounded-sm;
  border-color: $border-grey;
  width: 1rem;
  height: 1rem;
  color: $primary;
}
.icon {
  position: absolute;
  &.start {
    left: 10px;
  }
  &.end {
    right: 1rem;
  }

  top: 0.5rem;
  color: blue;
  @apply text-gray-400;
  &.focusedIcon {
    @apply text-primary-700;
  }
  &:focus {
    outline: none;
  }
}
