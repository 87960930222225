@import '../../styles/colors';

.tabs {
  @apply mb-px flex xl:space-x-8 md:space-x-2;
}

.tab {
  @apply select-none cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 hover:no-underline whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm;
  &Active {
    border-color: $primary;
    color: $black;
    &:hover {
      border-color: $primary;
      color: $black;
    }
  }
}

.tabsMobile {
  @apply w-full mb-px flex xl:space-x-8 md:space-x-2;
}

.tabMobile {
  @apply flex-1 w-max box-content text-center select-none border-transparent text-gray-500 whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm;
  flex-shrink: 0;
  min-height: min-content;
  &Active {
    border-color: $primary;
    color: $black;
  }
}
