@import '../../styles/colors';

.icon {
  &Background {
    &White {
      border-radius: 50%;
      background-color: $white;
      padding: 0.1rem;
    }
    &Green {
      border-radius: 50%;
      background-color: $light-green;
      padding: 0.1rem;
    }
    &Red {
      border-radius: 50%;
      background-color: $original-red;
      padding: 0.1rem;
    }
  }
  &Size {
    &Lg {
      width: 1.5rem;
      height: 1.5rem;
    }
    &Base {
      width: 1.25rem;
      height: 1.25rem;
    }
    &Sm {
      width: 1rem;
      height: 1rem;
    }
    &Xs {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  &Disabled {
    @apply cursor-not-allowed text-gray-500
  }
}
