@import '../../styles/colors';

.loading {
  @apply py-8;
  &Container {
    @apply flex justify-center items-center;
  }
  &Spinner {
    @apply animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900;
    animation-name: 'spin';
    border-color: $primary;
  }
  .MuiCircularProgress-svg {
    .MuiCircularProgress-circle {
      stroke: $primary;
    }
  }

}
