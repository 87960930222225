@import 'src/ui-kit/styles/screen_mixins';

.list {
  &Section {
    width: 20%;
    max-width: 20%;
    flex-shrink: 0;
    flex-grow:0;
    padding: 0 0.5rem;
    @include md {
      width: calc((100% - 15.5rem)/2);
    }
    div>span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      display: block;
      cursor: pointer;
    }
  }
  &SectionExtraOffset {
    padding-right: 1rem;
  }
  &SectionFixed {
    width: 20%;
    max-width: 20%;
    @include md {
      width: 5.5rem;
    }
  }
  &SectionShort {
    width: 15%;
    max-width: 15%;
  }
  &SectionExtraShort {
    width: 10%;
    max-width: 10%;
  }
  &IconSection {
    width: 20%;
    max-width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @include md {
      width: 5.5rem;
    }
    &Short {
      width: 10%;
      max-width: 10%;
    }
  }
}