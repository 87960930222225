@import 'variables';

// Button mixins
@mixin make-button($color, $label-color: $white) {
  background-color: $color;
  border: 2px solid $color;
  color: $label-color;
  .icon {
    color: $label-color;
  }
  &:hover {
    background-color: mix($color, $black, 70%);
    border: 2px solid mix($color, $black, 70%);

    .icon {
      color: $label-color;
    }
  }
}

@mixin make-button-bordered($color, $label-color: $white) {
  &Bordered {
    color: $color;
    background-color: transparent;
    border: 2px solid $color;

    .icon {
      color: $color;
    }

    &:hover {
      background-color: $color;
      color: $label-color;

      .icon {
        color: $label-color;
      }
    }
  }
}

@mixin make-button-transparent($color, $label-color: $white) {
  &Transparent {
    color: $color;
    background-color: transparent;
    border: transparent;

    .icon {
      color: $color;
    }

    &:hover {
      border: transparent;
      color: $color;
      background-color: $light-grey;

      .icon {
        color: $color;
      }
    }
  }
}

@mixin show-error-state() {
  border-color: $red;
  color: $red;
  &:focus {
    color: $black;
  }
  &::placeholder {
    color: $red;
  }
}
